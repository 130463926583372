.pagination {
  text-align: center;
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: bold;
}

.page {
  padding: 0 4px;
  background: #ffffff;
  vertical-align: top;
  display: inline-block;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
}

.pageLink {
  display: block;
}

.activeLink {
  color: #fac83c;
  cursor: default;
}

.previous {
  padding-right: 12px;
}

.next {
  padding-left: 12px;
}

.disabled {
  color: #c0c4cc;
  background-color: #ffffff;
  cursor: not-allowed;
}
